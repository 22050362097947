import React from 'react';
import { PageProps } from 'gatsby';
import { Layout, Grid } from '../components/Layout';
import { ContentfulType } from '../models/ContentfulTypes';
import { DeviceTypePicker } from '../components/DeviceTypePicker';

interface Props extends PageProps {
  pageContext: {
    guideType: ContentfulType;
    language: string;
    contentfulId: string;
    deviceSlug: string;
  };
}

export default ({ pageContext }: Props) => {
  const { guideType, language, contentfulId, deviceSlug } = pageContext;
  return (
    <Layout contentfulId={contentfulId} deviceSlug={deviceSlug}>
      <Grid>
        <DeviceTypePicker
          help={guideType.help}
          title={guideType.title}
          description={guideType.description?.description}
          devices={guideType.guideEntityWrappers}
          language={language}
        />
      </Grid>
    </Layout>
  );
};
