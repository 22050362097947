import React, { useEffect } from 'react';
import {
  TeliaP,
  TeliaHeading,
  TeliaRow,
  TeliaLink,
  TeliaButton,
} from '@teliads/components/react';
import { ContentfulGuideWrapper, Help } from '../models/ContentfulTypes';
import { getUrlWithLanguage } from '../utils';
import { useModalState } from '../context/ModalContext';

type DeviceTypePickerType = {
  title: string;
  description: string;
  devices: ContentfulGuideWrapper[];
  language: string;
  help?: Help;
};

export const DeviceTypePicker = ({
  title,
  description,
  devices,
  language,
  help,
}: DeviceTypePickerType) => {
  const { toggleModal, setEntity, entity: modalEntity } = useModalState();

  useEffect(() => {
    if (help) {
      setEntity(help);
    }
  }, []);

  return (
    <TeliaRow className="column gap center padding--vertical">
      <TeliaHeading tag="h2" variant="display-100">
        {title}
      </TeliaHeading>
      <TeliaP>{description}</TeliaP>
      {devices.map((device, index) => {
        const url = getUrlWithLanguage(
          language,
          device.slug ? `/${device.slug}` : `/${device.contentful_id}`
        );
        return (
          <TeliaLink
            key={device.contentful_id}
            href={url}
            className="telia-link--select"
            silent
            disableVisited
          >
            <article>
              <TeliaHeading tag="h3" variant="title-100">
                {device.title}
              </TeliaHeading>
              <img src={device.image?.file.url} />
            </article>
          </TeliaLink>
        );
      })}
      {modalEntity && (
        <TeliaRow className="column center padding--vertical">
          <TeliaButton variant="text" onClick={toggleModal}>
            <TeliaP>{modalEntity?.title}</TeliaP>
          </TeliaButton>
        </TeliaRow>
      )}
    </TeliaRow>
  );
};
